import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import Tags from './tags'

const ArticlePreview = ({ posts }) => {
  if (!posts) return null
  if (!Array.isArray(posts)) return null

  return (
    <div className="w-5/6 pl-48 pr-40">
      {posts.map((post) => {
        return (
          <div key={post.node.slug} className="mb-12">
            <GatsbyImage
              alt=""
              className="w-72"
              image={post.node.image?.gatsbyImage}
            />
            <h2 className="text-2xl mb-4 mt-4">{post.node.title}</h2>
            <div>{post.node.body?.raw && renderRichText(post.node.body)}</div>
            <div className="mt-4">
              <small className="meta">{post.node.date}</small>
              <Tags tags={post.node.tags} />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ArticlePreview
