import React from 'react'
import { graphql } from 'gatsby'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import ArticlePreview from '../components/article-preview'

const Catalog = ({ data, location }) => {
  const releases = data.allContentfulCatalog.edges

  return (
    <Layout location={location}>
      <Seo title="releases" />
      <Hero title="releases" />
      <ArticlePreview posts={releases} />
    </Layout>
  )
}

export default Catalog

export const pageQuery = graphql`
  query CatalogQuery {
    allContentfulCatalog(sort: { fields: [releaseDate], order: DESC }) {
      edges {
        node {
          id
          url
          blurb {
            raw
          }
          audio {
            file {
              contentType
              url
            }
            description
            title
          }
          title
          platforms
          format
          cover {
            title
            gatsbyImage(width: 480)
            description
          }
        }
      }
    }
  }
`
